import { Gif } from '@giphy/react-components'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .${Gif.imgClassName} {
        object-fit: cover;
    }
    [data-giphy-is-sticker='true'] {
        .${Gif.imgClassName} {
            object-fit: contain;
        }
    }
`
