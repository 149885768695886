import Script from 'next/script'

const HTLScript = () => {
    return (
        <>
            <link rel="stylesheet" type="text/css" href="https://htlbid.com/v3/giphy.com/htlbid.css" />
            <Script src="https://htlbid.com/v3/giphy.com/htlbid.js" id="htlbid-js"></Script>
        </>
    )
}

export default HTLScript
